import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";

const Footer = ({ facebookLink, instagramLink, linkedinLink }) => {
  const data = useStaticQuery(graphql`
    query {
      menu: allContentfulPage(
        filter: { isFooter: { eq: true } }
        sort: { fields: orderFooter, order: ASC }
      ) {
        nodes {
          slug
          title
        }
      }
    }
  `);
  const menu = data?.menu?.nodes;
  const followUs = "Suivez-nous sur les réseaux sociaux";

  return (
    <footer className="footer">
      {/* {(facebookLink || instagramLink || linkedinLink)
         && ( */}
      <div className="footer__top bg-dark-gray">
        <div className="grid-container">
          <div className="text-secondary text-large text-uppercase mb-1-25">
            {followUs}
          </div>
          <div className="footer__top--rs">
            {facebookLink && (
              <a
                href={facebookLink}
                target="_blank"
                rel="noreferrer"
                aria-label="Page Facebook"
              >
                <FaFacebookF size={35} />
              </a>
            )}
            {instagramLink && (
              <a
                href={instagramLink}
                target="_blank"
                rel="noreferrer"
                aria-label="Page Instagram"
              >
                <FaInstagram size={35} />
              </a>
            )}
            {linkedinLink && (
              <a
                href={linkedinLink}
                target="_blank"
                rel="noreferrer"
                aria-label="¨Page Linkedin"
              >
                <FaLinkedin size={35} />
              </a>
            )}
          </div>
        </div>
      </div>
      {/* ))} */}

      <div className="footer__bottom bg-light-gray">
        <div className="grid-container">
          <div className="footer__bottom--links">
            {menu.map((item, index) => {
              return (
                <Link key={index} className="footer--link" to={`/${item.slug}`}>
                  {item.title} -{" "}
                </Link>
              );
            })}

            <span>
              création
              <a
                className="mb-0 copyright"
                href="https://developpeur-web-strasbourg.fr"
              >
                {" "}
                JEDI{" "}
              </a>{" "}
              © {new Date().getFullYear()}
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
