import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import Phone from "../atoms/Phone";
import { FaRegEnvelope } from "react-icons/fa";

const ContactLink = () => {
  const data = useStaticQuery(graphql`
    query {
      options: contentfulElementsGlobauxDuSite {
        phone
      }
    }
  `);
  const text =
    "Vous voulez en savoir plus sur nos visites de Strasbourg en courant ?";

  const buttonText = "Contactez-nous";
  const path = "/contact";
  const phoneText = "Appelez-nous";
  return (
    <section className="full-width bg-dark-gray padding-small">
      <div className="grid-container">
        <div className="text-white text-large text-center mb-2">{text}</div>
        <div className="ctas">
          <Link to={path} className="button primary neon mb-0 with-picto">
            <FaRegEnvelope />
            {buttonText}
          </Link>
          <Phone phoneText={phoneText} phone={data?.options?.phone} />
        </div>
      </div>
    </section>
  );
};

export default ContactLink;
