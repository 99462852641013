import React from "react";
import { getSrc } from "gatsby-plugin-image";

const Logo = ({ logo }) => {
  const pathToImage = getSrc(logo);

  return (
    <img
      src={pathToImage}
      alt={logo.title || "Logo Strasbourg Running Tours"}
      width={110}
      height={110}
    ></img>
  );
};

export default Logo;
