import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image";

const Head = ({ title, description, ogImage = null }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
    }
  `);

  return (
    <Helmet title={`${title || data.site.siteMetadata.title}`}>
      <html lang="fr-FR" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0"
      ></meta>
      <link rel="canonical" href="https://strasbourg-running-tours.fr" />
      <meta name="robots" content="index, follow"></meta>
      <meta
        name="description"
        content={description || `${title} | ${data.site.siteMetadata.title}`}
      />
      <meta
        property="og:description"
        content={description || `${title} | ${data.site.siteMetadata.title}`}
      ></meta>
      <meta
        property="og:image"
        content={
          ogImage
            ? getSrc(ogImage)
            : `${data.site.siteMetadata.siteUrl}/default-image.jpg`
        }
      ></meta>
      <meta property="og:locale" content="fr-FR"></meta>
      <meta property="og:type" content="website"></meta>
      <meta
        property="og:title"
        content={`${title} | ${data.site.siteMetadata.title}`}
      ></meta>
      <meta
        name="googlebot"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      ></meta>
      <meta
        name="bingbot"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      ></meta>
      <meta name="referrer" content="origin" />
    </Helmet>
  );
};

export default Head;
