import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Header from "./header";
import Footer from "./footer";
import "../styles/index.scss";
import CookieConsent from "react-cookie-consent";
import { useLocation } from "@reach/router";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

const Layout = ({ title, children }) => {
  const data = useStaticQuery(graphql`
    query {
      options: contentfulElementsGlobauxDuSite {
        logo {
          gatsbyImageData(layout: FIXED, width: 110, height: 110)
        }
        facebookLink
        linkedinLink
        instagramLink
      }
    }
  `);
  const { logo, facebookLink, linkedinLink, instagramLink } = data?.options;

  const location = useLocation();

  return (
    <div className="main-wrapper">
      <Header title={title} logo={logo.gatsbyImageData} />
      <main className="grid-container">{children}</main>
      <Footer
        facebookLink={facebookLink}
        linkedinLink={linkedinLink}
        instagramLink={instagramLink}
      />
      <CookieConsent
        location="bottom"
        buttonText="J'ai compris"
        enableDeclineButton
        declineButtonText="Je refuse"
        flipButtons
        cookieName="gatsby-gdpr-google-analytics"
        onAccept={() => {
          initializeAndTrack(location);
        }}
        style={{ background: "#0f3057" }}
        containerClasses="cookieConsentContainer"
        buttonStyle={{
          color: "#FFF",
          backgroundColor: "#6A7BBB",
          fontSize: "16px",
          padding: "10px",
          borderRadius: "5px",
        }}
        declineButtonStyle={{
          color: "#000",
          backgroundColor: "#FFF",
          fontSize: "16px",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        Nous utilisons des cookies pour vous fournir la meilleure expérience
        utilisateur possible sur ce site. <br></br>
        <span style={{ fontSize: "12px" }}>
          Si vous continuez, nous considérons que vous est d'accord avec ce
          principe.
        </span>
      </CookieConsent>
    </div>
  );
};

export default Layout;
