import React, { useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";

const Phone = ({ phoneText, phone }) => {
  const [showPhone, setShowPhone] = useState(false);

  return showPhone ? (
    <a
      href={`tel:${phone}`}
      className="button with-picto secondary neon no-letter-spacing"
    >
      <FaPhoneAlt />
      {phone}
    </a>
  ) : (
    <a
      href="/"
      className="button with-picto secondary neon"
      onClick={(e) => {
        e.preventDefault();
        setShowPhone(true);
      }}
    >
      <FaPhoneAlt />
      {phoneText}
    </a>
  );
};

export default Phone;
