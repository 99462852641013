import React, { useEffect, useState } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Logo from "./atoms/Logo";
import Hamburger from "hamburger-react";
import { FaChevronUp } from "react-icons/fa";

const Header = ({ title, logo }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      menu: allContentfulPage(
        filter: { inHeader: { eq: true } }
        sort: { fields: orderHeader, order: ASC }
      ) {
        nodes {
          slug
          title
          menuLabel
        }
      }
    }
  `);

  const [isOpen, setOpen] = useState(false);

  const menu = data?.menu?.nodes;

  const [headerScrolled, setHeaderScrolled] = useState(false);
  const [pageScrolled, setPageScrolled] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        setHeaderScrolled(window.pageYOffset > 200);
        setPageScrolled(window.pageYOffset > 500);
        return;
      });
    }
  }, []);

  return (
    <>
      <header className="header" id="top">
        <div className="sticky-inner">
          <div className="bg-white header--padding">
            <div className="grid-container">
              <div className="d-flex space-between">
                <div className="d-flex">
                  <Link
                    className={`logo ${headerScrolled ? "logo--scrolled" : ""}`}
                    to="/"
                  >
                    <Logo logo={logo} />
                  </Link>
                  {/* <h1 className="h3">{title || data.site.siteMetadata.title}</h1> */}
                </div>

                <div
                  className={`header--main-nav ${
                    isOpen && "header--main-nav--active"
                  } ${headerScrolled ? "header--main-nav--scrolled" : ""}`}
                >
                  <nav>
                    <ul className="header--nav-list">
                      <li>
                        <Link
                          className="header--nav-item"
                          activeClassName="header--nav-item__active"
                          to="/"
                        >
                          Accueil
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="header--nav-item"
                          activeClassName="header--nav-item__active"
                          to="/parcours"
                        >
                          Nos parcours
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="header--nav-item"
                          activeClassName="header--nav-item__active"
                          to="/actualites"
                        >
                          News
                        </Link>
                      </li>
                      {menu.map((item, index) => {
                        return (
                          <li key={index}>
                            <Link
                              className="header--nav-item"
                              activeClassName="header--nav-item__active"
                              to={`/${item.slug}`}
                            >
                              {item.menuLabel || item.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </nav>
                </div>
                <div className="hide-for-large">
                  <Hamburger
                    toggled={isOpen}
                    toggle={setOpen}
                    label="Voir le menu"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <a
        href="#top"
        className={`to-top ${pageScrolled ? "active" : ""}`}
        aria-label="Remonter en haut de page"
      >
        <FaChevronUp />
      </a>
    </>
  );
};

export default Header;
